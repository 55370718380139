.recommendedList_card{
  display: flex;
  flex-wrap: wrap;
  margin-top:1rem;
  
}
.recommendedList_item{
  margin:2rem;
}
.recommendedList_title{
  margin-left: 2.5rem;
}
  