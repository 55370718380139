#swiper-wrapper--banner {
  height: 270px;
  position: relative;

  .card-banner {
    padding-left: 0;
    height: 100%;
  }
  .swiper-pagination-bullets {
    right: 30px;

    .swiper-pagination-bullet {
      height: 10px;
      width: 10px;

      &-active {
        background: $primary;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    right: 20px;
    top: unset;
    left: unset;
    color: rgba(black, 0.6);
    transform: rotate(90deg);

    &:after {
      font-size: 22px;
    }
  }
  .swiper-button-prev {
    bottom: 50px;
  }
  .swiper-button-next {
    bottom: 15px;
  }
}

.swiper-wrapper--row {
  position: relative;
  padding-bottom: 60px;

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    bottom: 60px;
    top: unset;
    left: unset;
    color: rgba(black, 0.6);

    &:after {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .swiper-button-prev {
    right: 40px;
    bottom: 0;
  }
  .swiper-button-next {
    bottom: 0px;
  }
}


