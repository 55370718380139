.subscription-layout {
  padding-top: 40px;

  &--card-details {
    // height: 100vh;
    // position: relative;
    // &:before {
    //   position: absolute;
    //   content: "";
    //   background: #e3e3f8;
    //   top: 0;
    //   height: 100%;
    //   left: 0;
    //   width: 50%;
    // }
    .container {
      height: 100%;

      .subscription-right {
        // padding-left: 40px;
        @media (min-width: 1300px) {
          // padding-left: 80px;
        }
      }
    }
  }
  .container {
    max-width: 1300px;
    padding: 0 15px;
    margin: 0 auto;

    .logo {
      height: 34px;
      width: auto;
      display: inline-block;
      margin-bottom: 40px;
    }

    .subscription-left {
      h2.header-primary {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.01em;
        color: #000000;

        &.text-primary {
          // color: $primary;
          margin-bottom: 4rem;
        }

        @media (max-width: 1000px) {
          &.text-primary {
            // color: $primary;
            margin-bottom: 2rem;
          }
        }

        img {
          width: auto;
          height: 44px;
          margin-top: -15px;
        }
      }

      h3 {
        font-size: 2.6rem;
        font-weight: 400;

        span {
          color: rgba(black, 0.7);
        }

        &.subheader-bold {
          font-weight: 600;
        }
      }

      h5 {
        color: rgba(black, 0.7);
        font-size: 1.8rem;
        margin-top: 10px;
      }

      ul.feature-list {
        list-style-type: none;
        padding-left: 0;
        margin: 3rem 0 6rem;

        li {
          display: flex;
          font-size: 2rem;
          font-weight: 300;
          color: rgba(black, 0.7);
          align-items: center;
          margin-bottom: 15px;

          img {
            margin-right: 25px;
            margin-top: 7px;
          }
        }
      }

      .ant-divider {
        height: 2px;
        border-top: 2px solid rgba(112, 112, 112, 0.2);
      }
      .invoice {
        margin-top: 4rem;

        &__row {
          display: flex;
          margin-bottom: 15px;
          color: rgba(black, 0.85);
          font-size: 1.8rem;

          > div {
            flex: 0 0 50%;
          }

          .invoice-title {
          }

          .invoice-detail {
            text-align: right;

            span {
              display: block;
              color: rgba(black, 0.7);
            }
          }
        }
      }

      .promotional-code {
        border: 1.5px solid #ccccdd;
        border-left: none;
        border-right: none;
        margin: 20px 0;
        padding: 20px 0;
      }

      button {
        border: none;
        cursor: pointer;
      }

      &--payment {
        width: 80%;
      }
    }

    .subscription-right {
      .ant-col {
        &:first-child {
          .subscription-info-card {
          }
        }
        .active {
          // background: transparent;
          // color: white;
          border: 2px solid #9797de;

          // .discount,
          // .price,
          // a {
          //   color: white;
          // }
        }
      }

      .loader-wrapper {
        margin: 0 auto;
      }

      .subscription-info-card {
        background: #ffffff;
        box-shadow: 0px 80px 80px rgba(0, 0, 0, 0.0140573),
          0px 33.4221px 33.4221px rgba(0, 0, 0, 0.0201946),
          0px 17.869px 17.869px rgba(0, 0, 0, 0.025),
          0px 10.0172px 10.0172px rgba(0, 0, 0, 0.0298054),
          0px 5.32008px 5.32008px rgba(0, 0, 0, 0.0359427),
          0px 2.21381px 2.21381px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        text-align: center;
        padding: 1.5rem 2rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid transparent;
        cursor: pointer;

        &-content-left {
          display: flex;
          justify-content: space-between;

          // flex-wrap: wrap;

          &-radio {
            width: 20px;
            height: 20px;
            border: 2px solid #9797de;
            border-radius: 50%;
            margin-top: 6px;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            &-under {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #9797de;
            }
          }

          &-text {
            text-align: left;
          }

          @media (max-width: 475px) {
            &-content-left {
              column-gap: 2px;
              &-radio {
                width: 30px;
                height: 20px;
                margin-right: 5px;
                &-under {
                  width: 7px !important;
                  height: 7px !important;
                }
              }
            }
          }

          @media (max-width: 320px) {
            &-radio {
              width: 22px;
              height: 13px;
              margin-right: 5px;
              &-under {
                width: 5px !important;
                height: 5px !important;
              }
            }
          }
        }

        .popular {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          border-radius: 100px;
          padding: 7px 25px;
          color: $primary;
          font-size: 1.6rem;
          box-shadow: 0 2px 16px rgba(black, 0.1);
        }

        .time {
          font-weight: 500;
          font-size: 32px;
          line-height: 30px;
          letter-spacing: 0.2px;
          margin: 0;
          padding-bottom: 10px;
          color: #000000;
          text-transform: uppercase;
        }

        .discount {
          font-size: 1.6rem;
          margin: 0px 0 25px;
          color: rgba(black, 0.7);
          font-weight: 300;
        }

        .price {
          font-size: 4.4rem;
          font-weight: 500;
          margin-bottom: 3.5rem;
        }

        a {
          color: $primary;
          font-size: 2rem;
        }
      }

      .credit-card-wrapper {
        margin-bottom: 35px;
        .card-element-wrapper {
          border: 1px solid #d6d6d6;

          border-radius: 8px;
          padding: 11px 15px;
          &:first-child {
            border-radius: 0px 0px 0px 8px;
            border-top: 0px;
            border-right: 0px;
          }
          &:last-child {
            border-radius: 0px 0px 8px 0px;
            border-top: 0px;
          }
        }
      }

      .card-number-wrapper {
        width: 100%;
      }
      .card-icon-list {
        display: flex;
      }

      .auth-form-wrapper {
        .credit-card-wrapper {
          h3 {
            font-size: 1.8rem;
          }

          .credit-card-icon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #d6d6d6;
            padding: 12px 15px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            p {
              color: rgba(black, 0.4);
              margin-bottom: 0;
            }

            img {
              &:not(:last-child) {
                margin-right: 15px;
              }
            }
          }
          @media (max-width: 453px) {
            .credit-card-icon {
              display: flex;
              // justify-content: space-between;
              // align-items: center;
              flex-direction: column;
              row-gap: 15px;
            }
          }

          .credit-card-info {
            display: flex;

            > div {
              flex: 0 0 50%;
            }

            .ant-input.ant-input-lg {
              border-radius: 0;
            }
          }
        }

        .btn.btn-primary {
          margin-top: 4rem;
        }
      }
    }
  }
  .cancel-wrapper {
    // display: flex;
    // justify-content: flex-end;
    position: absolute;
    right: 50px;
    z-index: 10;
  }
}

.promo-card-container {
  &-wrapper {
    background: #ffffff;
    box-shadow: 0px 56px 138px rgba(0, 0, 0, 0.0168687),
      0px 23.3955px 57.6531px rgba(0, 0, 0, 0.0242336),
      0px 12.5083px 30.8241px rgba(0, 0, 0, 0.03),
      0px 7.01207px 17.2797px rgba(0, 0, 0, 0.0357664),
      0px 3.72406px 9.17714px rgba(0, 0, 0, 0.0431313),
      0px 1.54966px 3.81881px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 16px 40px;
    margin-bottom: 25px;

    &-form {
      display: flex;
      margin-top: 10px;
      width: 100%;

      input {
        height: 48px;
      }

      &-btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        @media (max-width: 1025px) {
          font-size: 1.6rem;
          padding: 16px 30px 16px;
        }
      }
    }
  }
}

.payment-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-title {
  text-align: center;
  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 176%;
    color: #1c1b1f;
  }
  .choose-package-title {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #9797de;
  }
}

.promocode-tag {
  display: flex;
  align-items: center;
  background-color: #9797de;
  padding: 4px 6px;
  // width: 160px;
  border-radius: 2px;
  color: #ffffff;

  &-close {
    margin-left: 8px;
    margin-top: 2px;
    cursor: pointer;
  }
}

@media (max-width: 475px) {
  .time,
  .price {
    font-size: 20px !important;
  }
}

.payment-progress-message {
  color: #9797de;
  text-align: center;
}

@media (max-width: 1000px) {
  .subscription-layout {
    padding-top: 20px !important;
  }
}

.offer-payment-container {
  .subscription-layout {
    padding-top: 0px;

    .promo-card-container-wrapper {
      background: #f5f5ff;
      border-radius: 12px;
      box-shadow: none;
      padding: 18px 20px;

      .form-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #444444;
      }

      .ant-form-item {
        margin-top: 16px;
        background: #f9f9ff;
        border-radius: 8px;
      }
    }
  }
}
