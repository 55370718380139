.category-tags {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid #9797de;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    background-color: #e1e1ff;
  }

  &.selected {
    background-color: #9797de;
    transition: 0.3s all ease-in-out;
    span {
      color: #fff;
    }
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: #9797de;
  }
}
