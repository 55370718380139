.banner-wrapper {
    margin-top: 100px;
    background: #f5f5ff;
    column-gap: 20px;
    padding-top: 50px;
    padding: 40px;
    border-radius: 16px;
    overflow: hidden;


    &_left {
        display: flex;
        flex-direction: column;
        h2 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 44px;
            line-height: 140%;
            /* identical to box height, or 62px */

            letter-spacing: 0.6px;

            /* onBackground */

            color: #444444;
        }

        p {
            height: 84px;

            /* Body L */

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            /* or 156% */

            letter-spacing: 0.015em;

            /* onBackground */

            color: #444444;
        }

        //
        .form-wrapper {
            label {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                /* identical to box height, or 138% */
                text-align: center;
                letter-spacing: 0.01em;
                /* onBackground */
                color: #444444;
            }
            input {
            width: 452px;
            max-width: 452px;

                height: 50px;
                /* Background */
                border-radius: 8px;
                background: #f9f9ff;
                border-radius: 8px !important;
            }
            button {
                background: #9797de;
                border-radius: 8px;
                width: 196px;
                height: 60px;
                color: #fff;
            }
        }
    }


    @media only screen and (min-width: 310px) and (max-width: 560px) {
        // do something in this width range.
        .form-wrapper {
    margin-top: 60px;
            input {
            width: 100%;
                height: 50px;
                /* Background */
                border-radius: 8px;
                background: #f9f9ff;
                border-radius: 8px !important;
            }
     
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 430px) {
        // do something in this width range.
        .form-wrapper {
    margin-top: 100px;
            input {
            width: 100%;
                height: 50px;
                /* Background */
                border-radius: 8px;
                background: #f9f9ff;
                border-radius: 8px !important;
            }
     
        }
    }
    

    &_right {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .banner-right {
        display: flex;
        justify-content: flex-end;
        img{
            margin-top: -40px;
            height: 100%;
            width: 100%;

      

        }
    }

@media (max-width:767px) {
    .banner-right {
        display: flex;
        justify-content: flex-start;
        img{
            margin-top: 0px;
            height: 80%;
            width: 80%;

      

        }
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
	// do something in this width range.
    .banner-right {
        display: flex;
        justify-content: flex-end;
        img{
            height: 80%;
            width: 80%;
      

        }
    }
}


}
