#circleG {
  width: 97px;
  margin: auto;
}

.circleG {
  background-color: rgb(255, 255, 255);
  float: left;
  height: 21px;
  margin-left: 11px;
  width: 21px;
  animation-name: bounce_circleG;
  -o-animation-name: bounce_circleG;
  -ms-animation-name: bounce_circleG;
  -webkit-animation-name: bounce_circleG;
  -moz-animation-name: bounce_circleG;
  animation-duration: 1.6575s;
  -o-animation-duration: 1.6575s;
  -ms-animation-duration: 1.6575s;
  -webkit-animation-duration: 1.6575s;
  -moz-animation-duration: 1.6575s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  border-radius: 14px;
  -o-border-radius: 14px;
  -ms-border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
}

#circleG_1 {
  animation-delay: 0.3295s;
  -o-animation-delay: 0.3295s;
  -ms-animation-delay: 0.3295s;
  -webkit-animation-delay: 0.3295s;
  -moz-animation-delay: 0.3295s;
}

#circleG_2 {
  animation-delay: 0.7755s;
  -o-animation-delay: 0.7755s;
  -ms-animation-delay: 0.7755s;
  -webkit-animation-delay: 0.7755s;
  -moz-animation-delay: 0.7755s;
}

#circleG_3 {
  animation-delay: 0.9985s;
  -o-animation-delay: 0.9985s;
  -ms-animation-delay: 0.9985s;
  -webkit-animation-delay: 0.9985s;
  -moz-animation-delay: 0.9985s;
}

@keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(151, 151, 222);
  }

  100% {
  }
}

@-o-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(151, 151, 222);
  }

  100% {
  }
}

@-ms-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(151, 151, 222);
  }

  100% {
  }
}

@-webkit-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(151, 151, 222);
  }

  100% {
  }
}

@-moz-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: rgb(151, 151, 222);
  }

  100% {
  }
}
