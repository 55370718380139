@mixin maxWidth($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}

@keyframes frmBelow {
  from {
    transform: translateY(1000px);
  }
  to {
    transform: translateY(0);
  }
}

.unsticky {
  position: relative !important;
  @include maxWidth(992px) {
    position: fixed !important;
  }
}

.gv-player-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: frmBelow 0.5s ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.21);
  background-color: #fff;
  padding: 0px 5px;

  .ant-slider-track {
    background-color: #9797de !important;
  }
  .ant-slider-handle {
    border: 2px solid #9797de;
    border-color: #9797de !important;
    box-shadow: 0 0 0 5px rgba(#9797de, 0.12);
    height: 18px;
    width: 18px;
    margin-top: -7px;
    &:focus {
      box-shadow: 0 0 0 5px rgba(#9797de, 0.12);
    }
  }
  .gv_player {
    display: none;
  }
  .gv-player--controls {
    // &:hover{
    //   .gv-music-player-slider{
    //     .ant-slider-handle{
    //       transform:scale(1) !important;
    //     }
    //   }
    // }
    padding: 0.8em 1em;
    background-color: rgba(white, 0.9);
    @include maxWidth(992px) {
      padding: 0.4em;
    }
    position: relative;

    .gv-music-player-slider {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      // .ant-slider-handle{
      //   transform:scale(0) !important;
      //   transition: all 0.3s ease-in-out;
      // }
    }

    .gv-song-details {
      display: flex;
      height: 100%;
      cursor: pointer;
      @include maxWidth(992px) {
        justify-content: center;
      }
      align-items: center;
      .song-img {
        @include maxWidth(450px) {
          display: none;
        }
        overflow: hidden;
        width: 15%;

        .image {
          width: 55px;
          height: 55px;
          margin: auto;
          img {
            border-radius: 6px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .song-title {
        width: 65%;
        margin-left: 1em;
        @include maxWidth(992px) {
          margin-left: 0.5em;
        }
        line-height: 1.2;
        font-weight: 300;
        font-size: 14px;
        white-space: break-spaces;
      }

      .gv-player-favbutton {
        margin-left: 1em;
        width: 20%;
        @include maxWidth(992px) {
          margin-left: 0.5em;
        }
        color: #9797de;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .gv-player-control-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .next-prev-btn {
        font-size: 40px;
        margin: 0 0.3em;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .play-pause-btn {
        color: #9797de;
        cursor: pointer;
        font-size: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;
      }
    }

    .gv-player-settings {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      @include maxWidth(992px) {
        justify-content: center;
      }
      &--timer {
        span {
          color: #9797de;
        }
        color: #666;
        font-size: 18px;
      }
      &--volume {
        margin-left: 1.5em;
        @include maxWidth(992px) {
          margin-left: 0.5em;
        }
        display: flex;
        align-items: center;
        height: 100%;
        // &:hover{
        //   .volume-slider{
        //     .ant-slider-handle{
        //       transform:scale(1) !important;
        //     }
        //   }
        // }
        .volume-icon {
          display: flex;
          align-items: center;
          font-size: 25px;
          color: #9797de;
          cursor: pointer;
        }
        .volume-slider {
          width: 100px;
          @include maxWidth(450px) {
            width: 70px;
          }
          // .ant-slider-handle{
          //   transition: all 0.3s ease-in-out;
          //   transform:scale(0) !important;
          // }
          position: relative;
          z-index: 9999;
        }
      }
      .remaining-time {
        margin-top: -5px;
        font-size: 14px;
      }

      &--clock {
        margin-left: 1.5em;
        @include maxWidth(992px) {
          margin-left: 0.5em;
        }
        color: #666;
        transition: all 0.5s ease-in-out;
        font-size: 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        &:hover {
          color: #9797de;
        }
      }
    }
  }
  .close-player-wrapper {
    position: absolute;
    right: 15px;
    top: -35px;
    z-index: 55;
    background-color: #fff;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.gv-player-timer {
  .ant-menu-item {
    margin: 0 !important;
    &.active {
      color: #9797de !important;
    }
    &.ant-menu-item-selected {
      color: #000d !important;
      background-color: transparent !important;
      &.active {
        color: #9797de !important;
      }
    }
    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
}

.ant-menu-light .ant-menu-item:hover {
  color: #9797de !important;
}

.gv-player-timer .ant-menu-title-content {
  svg:not(:root) {
    margin-right: 5px;
    // margin-top:10px;
  }
}

.tooltip-upgrade-btn {
  color: #fff !important;
  text-decoration: underline;
}
