.public-layout {
  padding: 6rem;
  // background: linear-gradient(to bottom right, #f2f6fc, #f7fcfe);
    background-color: #f4f8fd;
;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1201px) {
    flex-direction: row;
    height: 100vh;
  }

  @media (max-width: 767px) {
    padding: 15px;
  }

  &__left {
    flex: 1 0 100%;
    
    height: 100%;
    // background: url("../../images/login-gradient-bg.png") center no-repeat;
    // display: grid;
  background-color: #E7E8FC;

    width: 50%;
    place-content: center;
    border-radius: 16px;
    background-size: cover;
    padding: 6rem 6rem  6rem 1rem;
    position: relative;
    @media (min-width: 1201px) {
      flex: 1 0 50%;
    }
    @media (max-width: 1201px) {
      flex: 1 0 50%;
      width: 100%;
    }

    .layout-img {
      width: 80%;
      height: auto;
      margin: 0 auto;
      @media (min-width: 1201px) {
        width: 100%;
      }
    }

    .brand-logo {
      position: absolute;
      top: 25px;
      left: 25px;
      height: 4rem;
    }
// carousel part

.left-wrapper
{
  margin-top:20%;
  .item{
    .cara-items{
      // width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      .cara-item-left
      {
        width: 100%;
      }


      .cara-item-right{
        

        h2{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 1000;
          font-size: 38px;
          line-height: 45px;
          /* or 118% */
          letter-spacing: 0.02em;
          color: #9797DE;
          // letter-spacing: 0.02em;
          
          /* GV/sys/light/primary */
          
          color: #9797DE;
        }
        p{
          font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 29px;
/* or 181% */

letter-spacing: 0.2px;

/* GV/sys/light/on-background */

color: #1C1B1F;
        }
      }
    }

 }

 .owl-carousel{
  position: relative;
  .owl-dots{
    position:absolute;
    bottom: -110px;
    right: 0px;
  }

 }

 @media(max-width:1300px)  {
  .owl-carousel{
    position: relative;
    .owl-dots{
      position:absolute;
      bottom: 0px;
      right: 0px;
    }
  
   }
  
}



}




  }
// for hide in mobile device
  
@media(max-width:480px)
{
  &__left{
display: none;
}
}

  &__right {
    flex: 1 0 100%;
    height: 100%;

    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
    @media (min-width: 1201px) {
      flex: 1 0 50%;
      margin-top: 0rem;
      padding: 0 5%;
    }
    .auth-wrapper {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      @media (min-width: 1201px) {
        max-width: 60rem;
        width: 100%;
      }

      h2 {
        font-size: 4.2rem;
        margin-bottom: 0.5em;
        @media (min-width: 1600px) {
          margin-bottom: 1em;
          font-size: 4.8rem;
        }
        span {
          font-weight: 700;
          display: block;
        }
      }
      &.auth-wrapper--signup {
        h2 {
          font-size: 3.6rem;
          font-weight: 700;
        }
      }
      .ant-form {
        label {
          font-size: 1.6rem;
          margin-bottom: 0px;
        }
        @media (min-width: 1600px) {
          font-size: 1.8rem;
        }
        .forgot-passsword{
          display: flex;
          justify-content: flex-end;
        }
        .text-small {
         
          margin-top: -15px;
          font-size: 1.4rem;
          text-align: left;
          span {
            color: #767676;
          }
          a {
            font-size: 1.4rem;
            margin-left: 7px;
          }
          &-account {
            margin-top: 0;
          }
        }
        .btn-large {
          width: 100%;
        }
      }

      &--success {
        display: grid;
        place-content: center;
        text-align: center;

        h2 {
          color: rgb(30, 141, 30);
          img {
            height: 44px;
          }
        }
      }

      p {
        margin: 5px 0;
        font-size: 2rem;
        &:last-child {
          font-size: 1.8rem;
          color: rgba(black, 0.6);

          span {
            margin-right: 7px;
          }
        }
      }
    }

    .social-login {
      text-align: center;
      .social-divider {
        position: relative;
        height: 4rem;
        span {
          background: #f5fafd;
          padding: 0 20px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
          font-size: 2rem;
        }

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          background: #dbdbdb;
          width: 100%;
          left: 0;
          top: 16px;
          z-index: 3;
        }
      }

      .social {
        display: flex;
        justify-content: center;
        margin: 1.5rem 0 1.5rem;
        @media (min-width: 1600px) {
          margin: 2.5rem 0 1.5rem;
        }
        &-icon {
          position: relative;
          height: 9rem;
          width: 9rem;
          background: white !important;
          box-shadow: 1px 2px 4px rgba(black, 0.1) !important;
          justify-content: center !important;
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          border-radius: 8px !important;
          & > div {
            margin: 0 !important;
            svg {
              font-size: 36px !important;
            }
          }
          span {
            font-family: "SofiaPro" !important;
            font-size: 1.5rem !important;
            color: #767676 !important;
            font-weight: 300 !important;
            padding: 0 !important;
          }

          // @media (min-width:1600px) {
          //     height: 7.5rem;
          // width: 7.5rem;
          // }

          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }

          img {
            width: 36px;
            height: auto;
            display: inline-block;
          }
          h4 {
            margin: 7px 0 0;
            font-size: 1.5rem;
            color: #767676;
            font-weight: 300;
          }
          &:not(:last-child) {
            margin-right: 25px;
          }
        }
      }
    }
  }
}


// .public-layout-auth{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: row;

//   .left{
//    width: 50%;

//   }
// }