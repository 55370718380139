.trendingContainer {
  h4 {
    margin-bottom: 2rem !important;
    font-size: 3.2rem !important;
    font-weight: 500 !important;
    margin-top: -6.3rem !important;
    margin-bottom: 4rem !important;
    width:100rem !important;
  }
}
