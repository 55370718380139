@import "../abstract/utility.scss";
.card {
  background: rgba(white, 0.35);
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
  .card-link {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
  }

  &--regular {
    border-radius: 18px;
    max-width: 30rem;
    min-height: 33rem;
    padding: 16px 16px 0;
    flex-direction: column;
    @media (max-width: 767px) {
      // max-width: 100%;
    }
    .card__header {
      width: 100%;
      padding-top: 100%;
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      .gv-icon {
        width: 26px;
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 7;
      }

      .card-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
      }

      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.15);
        z-index: 3;
      }
    }

    .card__body {
      padding: 15px 0 20px;

      h3 {
        margin-bottom: 0;
        font-size: 1.6rem;
        font-weight: 400;
        color: #343434;
      }
      .subtitle {
        font-size: 1.4rem;
        color: #909090;
        font-weight: 300;
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &--horizontal {
    padding: 1rem;
    border-radius: 8px;
    transition: all 450ms ease;
    &:hover {
      background: #fff;
      .card__img {
        .iconlyBulk-Play {
          visibility: visible;
          z-index: 15;
          cursor: pointer;
        }
      }
    }

    .card__img {
      width: 100px;
      height: 100px;
      position: relative;

      // flex: 1 0 auto;
      // flex:1;

      .iconlyBulk-Play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 48px;
        visibility: hidden;

        .path1 {
          color: rgba(255, 255, 255, 0.85);
          &::before {
            opacity: 1;
          }
        }

        .path2 {
          color: #000;
        }
      }

      img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    }
    .card__body {
      flex: 4;
      padding: 1rem;
      flex-grow: 1;
      position: relative;

      .card__add-btn {
        position: absolute;
        top: 50%;
        right: 25px;
        display: flex;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 15;
        .iconly-Plus {
          color: rgba(black, 0.6);
          font-size: 24px;
        }
      }
    }
  }
}

.card-round {
  width: 20rem;
  height: auto;
  position: relative;
  cursor: pointer;
  .image-wrapper {
    overflow: hidden;
    border-radius: 50%;
    border: 1rem solid white;
    width: 20rem;
    height: 20rem;
    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5rem;
  }

  &.card-round--title-center {
    margin-bottom: 0;
    h4 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%);
      font-size: 2.2rem;
      font-weight: 500;
      color: white;
      z-index: 5;
      width: 100%;
      margin: 0;
      text-shadow: 1px 1px 2px rgba(black, 0.3);
    }
  }
}

.disabled-card {
  width: 20rem;
  height: auto;
  position: relative;
  // margin-bottom: 3.5rem;
  cursor: not-allowed;
  .image-wrapper {
    overflow: hidden;
    border-radius: 50%;
    border: 1rem solid #9d9d9d;
    width: 20rem;
    height: 20rem;
    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5rem;
  }

  &.card-round--title-center {
    margin-bottom: 0;
    h4 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%);
      font-size: 2.2rem;
      font-weight: 500;
      color: white;
      z-index: 5;
      width: 100%;
      margin: 0;
      text-shadow: 1px 1px 2px rgba(black, 0.3);
    }
  }
}

.single-track-description {
  background: #ededfb;
  padding: 2rem;
  border-radius: 16px;
}

.card-banner {
  background: #ededfb;
  padding: 2rem;
  display: flex;
  border-radius: 16px;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // @media (max-width: 767px) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  &__img {
    // height: 318px;
    // max-width: 318px;
    // flex: 1 1 35%;

    img {
      height: 100%;
      width: 100%;
      // object-fit: cover;
      // object-position: center;
    }

    // @media (max-width: 767px) {
    //   display: none;
    // }
  }

  &__content {
    flex: 1 0 65%;
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    width: 75%;
    h5 {
      text-transform: uppercase;
      color: #909090;
      letter-spacing: 1px;
    }

    h2 {
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 2.8rem;
      line-height: 1.25;
      @media (min-width: 991px) {
        font-size: 3.8rem;
      }
      @media (min-width: 1300px) {
        font-size: 4.8rem;
      }
      @media (min-width: 1600px) {
        font-size: 5.6rem;
      }
    }
    p {
      font-size: 1.8rem;
      margin: 0.5rem 0 0.5rem;
    }
    .banner-subtext {
      margin: 1rem 0 3rem;
      margin-bottom: 0px;
      overflow: auto !important;
      @include hideScrollbar;
    }
    .banner-playlist-creator {
      color: #343434;
      font-weight: 500;
    }
    .banner-tracks {
      color: #767676;
    }

    .btn {
      cursor: pointer;
    }
  }
}

.card-song-list {
  background: #ededfb;
  padding: 2rem;
  border-radius: 18px;

  @media (max-width: 767px) {
    overflow: hidden;
    overflow-x: scroll;
  }
  .card-list-header {
    h3 {
      color: rgba(black, 0.6);
      font-size: 1.6rem;
    }
  }
  .row-song-list {
    display: flex;
    padding: 10px 0;
    // justify-content: center;
    align-items: center;
    // column-gap: 120px;

    > div {
      padding: 5px 7px;
    }
    .action-div {
      display: flex;
      column-gap: 10px;
      // align-items:center !important;
      // justify-content:center !important;
    }

    @media (max-width: 768px) {
      .action-div {
        flex-wrap: wrap;
        row-gap: 10px;
      }
    }

    .list-index,
    .list-more {
      flex: 1 0 5%;
      text-align: center;
      font-size: 2.4rem;
      font-weight: 700;
    }
    .list-index {
      color: #90909090;
    }
    .list-title {
      flex: 1 0 45%;
      display: inline-flex;
      align-items: center;
      position: relative;
      .card-premium {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #8686ee;
        padding: 2px 6px;
        border-radius: 2px;

        svg {
          color: #ffffff;
        }
      }
      @media (max-width: 767px) {
        flex: 1 0 90%;
      }
      .song-img {
        height: 7.5rem;
        max-width: 7.5rem;
        flex: 1 0 auto;
        cursor: pointer;
        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }
      }

      .song-locked-img {
        background-color: #dfe0f8;
        height: 7.5rem;
        max-width: 7.5rem;
        flex: 1 0 auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .song-title {
        padding: 0 7px 0 15px;
        cursor: pointer;

        a {
          color: $primary-text-color;
        }
      }
    }

    .list-fav,
    .list-duration,
    .list-action{
     padding:0rem 4rem 0rem 4rem !important;
    }
    .list-category {
      flex: 1 0 15%;
      text-align: center;
      font-weight: 300;
    }

    .list-more {
      font-size: 3.2rem;
      margin-top: -5px;
    }
  }
}

.card-selected {
  border: 1rem solid $primary;
  box-shadow: 0 0 5px rgba(black, 0.6);
  transition: all 0.5s ease-in-out;
  border-radius: 50%;
}

.card--check-icon {
  position: absolute;
  top: 15%;
  right: 9%;
  padding: 0.3em;
  border: 2px solid #8686ee;
  background: white;
  border-radius: 50%;
  color: #8686ee;
  width: 30px;
  height: 30px;
  path {
    stroke-width: 75;
  }
  animation: grow 0.3s ease-in-out;
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

// rectangle card
.card-rectangle {
  display: grid;
  grid-template-columns: 20% 70%;
  align-items: center;
  width: 270px;
  height: 112.54px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 38px 80px rgba(151, 151, 222, 0.0281146),
    0px 15.8755px 33.4221px rgba(151, 151, 222, 0.0403893),
    0px 8.4878px 17.869px rgba(151, 151, 222, 0.05),
    0px 4.75819px 10.0172px rgba(151, 151, 222, 0.0596107),
    0px 2.52704px 5.32008px rgba(151, 151, 222, 0.0718854),
    0px 1.05156px 2.21381px rgba(151, 151, 222, 0.1);
  border-radius: 8px;

  &-option {
    display: flex;
    justify-content: center;

    &-selected {
      width: 20px;
      height: 20px;
      background: #cecece;
      border-radius: 50%;
    }

    &-not-selected {
      width: 20px;
      height: 20px;
      background: #9797de;
      border-radius: 50%;
    }
  }
  &-content {
    h4 {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #1c1b1f;
      opacity: 0.3;
    }
    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1c1b1f;
    }
  }
}

.single-track-icons {
  display: flex;
  align-items: center !important;
  justify-content: center;
  column-gap: 20px;

  &-favourite {
    // margin-right: 20px;
    margin-left: 10px;
  }
}

.card--regular {
  position: relative;
  .card-premium {
    position: absolute;
    top: 28px;
    right: 28px;
    z-index: 999;
    padding: 2px 5px;
    background: #9797de;
    color: #ffffff;
    border-radius: 5px;
    // width: 100px;
    text-align: center;
  }
}

.category-card {
  height: 292.27px;
  background: #f9f9ff;
  box-shadow: 0px 100px 449px rgba(0, 0, 0, 0.0139364),
    0px 30.1471px 135.36px rgba(0, 0, 0, 0.02),
    0px 12.5216px 56.2218px rgba(0, 0, 0, 0.0260636),
    0px 4.5288px 20.3343px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  cursor: pointer;

  &-regular {
    padding: 10px 16px;
    position: relative;

    .card-premium {
      position: absolute;
      top: 10px;
      right: 16px;
      z-index: 999;
      padding: 2px 5px;
      background: #9797de;
      color: #ffffff;
      border-radius: 5px;
      // width: 100px;
      text-align: center;
    }

    &-header {
      position: relative;
      height: 194px;
      border-radius: 10px;

      &-img {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      &-duration {
        position: absolute;
        z-index: 2;
        background: #9797de;
        padding: 4px 8px;
        opacity: 0.9;
        border-radius: 0px 10px;
        color: #fff;
        left: 0px;
        bottom: 0px;
      }
    }

    &-body {
      &-writer {
        display: flex;
        align-items: center;
        margin-top: 12px;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 135%;
          color: #1c1b1f;
          opacity: 0.5;
          margin-left: 4px;
        }
      }

      h3 {
        margin-top: 16px;
      }
    }
  }
}
