.mid-component {
    margin-top: 50px;
    .heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {

            /* Headline S */

            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 700;
            font-size: 44px;
            line-height: 140%;
            /* identical to box height, or 62px */

            letter-spacing: 0.6px;

            /* onBackground */

            color: #444444;
        }
    }
.item-container{
padding: 10px;
    .item-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        .common {
            width: 88px;
            height: 88px;
            border-radius: 100px;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

            }
        }

        &_left {
            background: #C7F4F2;
            position: relative;

         

        }
        &_left-second{
            position: relative;
            background: #FFE8B9;
  
        } 
        &_left-third{
            position: relative;
            background: #F9E5F6;
       
        } 

        &_right {
            display: flex;
            flex-flow: column;
            row-gap: 10px;

            .step {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: #9797DE;
                /* or 22px */

                letter-spacing: 0.6px;
                text-transform: uppercase;
            }
            .content{
                font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
/* identical to box height */

letter-spacing: 0.015em;

/* onBackground */

color: #444444;
            }
        }
    }
    .message{
        margin-top: 20px;
        font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
/* or 175% */

// letter-spacing: 0.015em;

/* onBackground */

color: #444444;
    }
}
}