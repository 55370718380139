.row-card-list {
  // display: flex;
  // flex-wrap: wrap;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  .card-list__item {
    padding: 0 1.5rem;
    width: 20% !important;
  }
  @media (max-width: 1800px) {
    .card-list__item {
      width: 25% !important;
    }
  }
  @media (max-width: 1100px) {
    .card-list__item {
      width: 33.3333% !important;
    }
  }
  @media (max-width: 767px) {
    .card-list__item {
      width: 50% !important;
    }
  }

  @media (max-width: 480px) {
    .card-list__item {
      width: 100% !important;
    }
  }
}

.premium-message-modal {
  &-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.2px;
    color: #1c1b1f;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-btn {
    display: flex;
    justify-content: end;
    align-items: center;

    button:first-child {
      margin-right: 10px;
    }
  }
}
