.dropdown-more-option {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #9797de;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
    font-size: 2.5rem;
  }
}
