.filter {
  &_index {
    &_btn {
      margin-bottom: 32px;
      .ant-btn {
        // background-color: #9797de;
        color: #000;
        height: 48px;
        font-size: 16px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        &:hover,
        &:focus {
          background-color: #9797de;
          color: #fff;
          border-color: transparent;
        }
      }
      &_img {
        margin-right: 10px;
        height: 20px;
      }
    }
  }
  &_sub {
    &_cat {
      margin-bottom: 32px;
      &_title {
        padding-bottom: 8px;
      }
      &_btns {
        margin-top: 0px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .ant-btn {
          background-color: #ffff;
          color: #000;
          height: 28px;
          font-size: 16px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          margin-right: 18px;
          margin-bottom: 22px;
          &:hover,
          &:focus {
            background-color: #9797de;
            color: #fff;
            border-color: transparent;
          }
          &.selected {
            outline: 3px solid #545485;
            background-color: #9797de;
            color: #fff;
          }
        }
      }
    }
  }
  &_btns {
    .ant-btn {
      background-color: #ffff;
      color: #000;
      height: 48px;
      font-size: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin-right: 18px;
      margin-bottom: 22px;
      &:hover,
      &:focus {
        background-color: #9797de;
        color: #fff;
        border-color: transparent;
      }
      &.selected {
        outline: 3px solid #545485;
        background-color: #9797de;
        color: #fff;
      }
    }
  }
}

.searchResult {
  &_searchTitle {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    h2 {
      margin-bottom: 0px;
    }

    img {
      margin-right: 10px;
    }
  }

  .card-song-list{
    max-height: 1000px;
    overflow-y: scroll;
 
  }

  .card-song-list::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .card-song-list::-webkit-scrollbar-track {
    background: #ededfb;

  }
   
  /* Handle */
  .card-song-list::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .card-song-list::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}
