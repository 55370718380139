.profile-wrapper {
  .row--profile-info {
    .card-profile {
      display: flex;
      flex-direction: column;
      padding: 2rem 3rem;
      height: 100%;
      // justify-content: center;
      &.card-profile--info {
        align-items: center;
        text-align: center;
        font-size: 1.8rem;
        .profile-img {
          height: 125px;
          width: 125px;
          border-radius: 50%;
          overflow: hidden;
          img {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
          }
        }

        .username {
          font-size: 2.8rem;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .usertype {
          font-size: 1.8rem;
          position: relative;
          color: #bdbdbd;
          margin-bottom: 5px;

          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            position: absolute;
            left: -15px;
            top: 11px;
            background: #bdbdbd;
          }

          &.green {
            color: #60bd5b;

            .dot {
              background: #60bd5b;
            }
          }
        }

        .user-handle {
          font-size: 1.6rem;
          color: rgba(black, 0.4);
        }

        .daily-goal {
          margin-bottom: 5px;
        }

        .fav-track {
          font-weight: 500;

          span {
            color: rgba(black, 0.6);
            margin-right: 5px;
            font-weight: 400;
          }
        }
      }

      &.card-profile--dashboard {
        padding-top: 30px;
        padding-bottom: 30px;
        justify-content: center;
        .card__header {
          margin-top: 30px;
          margin-bottom: 5px;
        }
      }

      .card__header {
        font-size: 2.2rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          font-size: 1.4rem;
          color: rgba(black, 0.4);
        }
      }
      .interaction-graph {
        .ant-tabs-nav {
          margin-bottom: 0;

          .ant-tabs-tab {
            padding-top: 0;

            .ant-tabs-tab-btn {
              font-size: 1.6rem;
              font-weight: 500;
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $primary;
              }
            }
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
        .progress-container {
          display: flex;
          align-items: center;
          justify-content: center;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .days {
            font-size: 15x;
            color: rgba(0, 0, 0, 0.37);
            width: 48px;
            //    flex:1;
          }

          .ant-progress-line {
            margin-top: 0;
            line-height: 1;
          }
          .progress-graph {
            .ant-progress-bg {
              background: $primary;
            }
          }
        }
      }

      .upgrade-btn {
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .healing-info {
        display: flex;
        align-items: center;
        margin-top: 3rem;

        .healing-graph {
          position: relative;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .healing-text {
          margin-left: 15px;

          h4 {
            font-size: 1.8rem;
          }

          p {
            margin-bottom: 0;
            color: rgba(black, 0.4);
          }
        }
      }

      .goal-progress {
        margin: 0rem 0 2.5rem;
        .ant-progress {
          margin-bottom: 10px;

          .ant-progress-text {
            margin: 0;
            width: 64px;
          }
        }
        a {
          margin-top: 15px;
        }
      }
      .add-tags {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          font-size: 3.4rem;
          margin-right: 10px;
        }

        .following_tags_container {
          display: flex;

          &__item {
            overflow: hidden;
            border-radius: 100%;
            border: 3px solid white;
            box-shadow: 0px 2px 3px #afc2e1;
            background-color: #ededfa;
            img {
              height: 41px;
              width: 41px;
            }

            &:not(:first-child) {
              margin-left: -20px;
            }
          }
        }
      }

      .tag-progress {
        margin-top: 1.5rem;

        &__item {
          .ant-progress-text {
            width: 64px;
            margin: 0;
          }
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.card-profile--dashboard {
  .profile-img {
    border-radius: 50%;
    overflow: hidden;
  }
}

.daily-goal-modal {
  &--item {
    font-size: 14px;
    border: 1px solid gray;
    padding: 5px 13px;
    border-radius: 7px;
    margin-bottom: 10px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
    &:first-child {
      margin-top: 10px;
    }
  }
  .active {
    background-color: $primary;
    border: 1px solid $primary;
  }
}
