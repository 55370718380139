.category-tracks {
  margin-top: 25px;

  @media (min-width: "1600px") {
    .ant-row {
      .ant-col-xxl-4 {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}

.category-tracks {
  margin-top: 25px;
}

.category-container {
  .category-tags-container {
    margin-top: 30px;
    position: relative;

    // .review-swiper-button-prev,
    // .review-swiper-button-next {
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }

    // .review-swiper-button-prev {
    //   left: -25px;
    // }

    // .review-swiper-button-next {
    //   right: -25px;
    // }

    .swiper-wrapper--row {
      padding-bottom: 0;
      overflow: unset;

      .swiper-button-prev {
        left: -25px;
        // z-index: 99999;
      }
    }
  }
}

.category-children-section {
  .dash-section {
    padding: 0;
    border-top: none;
  }
}
