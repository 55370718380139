.ant-form {
  &-item {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    &-label {
      label {
        font-size: 18px;
        font-weight: 500;
        height: 22px !important;
        @media (min-width: 1600px) {
          height: 30px !important;
        }
      }
    }

    &-control {
      &-input {
        min-height: 50px;
      }
    }

    .ant-form-item-control-input-content {
      > span {
        margin-right: 7px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.ant-form-large .ant-form-item-control-input {
  .form-input-control {
    padding: 12px 15px;
    border-radius: 8px;
    width: 100%;
  }

  .form-input-select {
    height: 50px;
    border-radius: 8px;
    .ant-select-selector {
      height: 50px;
      border-radius: 8px;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: 50px !important;
      }
    }
  }
}

.ant-form-large {
  .ant-form-item-control-input {
    .ant-input-affix-wrapper-lg {
      padding: 8px 15px;
      border-radius: 8px;

      @media (min-width: 1600px) {
        padding: 12px 15px;
      }
      .ant-input-lg {
        padding: 0;
      }
    }
  }
}

// .ant-form-item-required::after{
//     content: "*";
// }

//for asterisk at the back
.name-on-card {
  .ant-form-item-label {
    .ant-form-item-required {
      &:before {
        position: absolute;
        right: -15px;
        top: 3px;
      }
    }
  }

  .ant-input {
    border-radius: 8px;
    padding: 8px 15px;
  }
}

.asterisk {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
