.forgot-layout {
    padding: 6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    .link-btn-nobg{
      width: 84%;
    padding: 0.5em 1em;
  border-radius: 8px;
  display: block;
  text-align: center;
  border:1px solid $primary ;
  color: $primary;
  
  // margin-top: 25px;
  margin: 10px auto;
  
  a{
  
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $primary;
  }
      &:hover,
      &:focus {
        // background: darken($primary, 10%);
        // color: #000;
        outline: 0;
      }
  }
input{
    border-radius: 8px;
    border: 1px solid #000000;
}


font-family: 'Poppins';
font-style: normal;

&__form {
    flex: 1 0 100%;
    height: 100%;

    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;



    @media (min-width: 1201px) {
      flex: 1 0 50%;
      margin-top: 0rem;
      padding: 0 5%;
    }
    // seperate
    .auth-wrapper {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      @media (min-width: 1201px) {
        max-width: 60rem;
        width: 100%;
      }
      .heading{
        h2{
        font-family: 'Poppins';
        font-style: normal;
        font-size: 38px;
        letter-spacing: 0.02em;
        font-weight: bold;
        color: #1C1B1F;
      }

 
    }

      h2 {
        font-size: 38px;
        margin-bottom: 0.5em;
        @media (min-width: 1600px) {
          margin-bottom: 1em;
          font-size: 4.8rem;
        }
        span {
          font-weight: 700;
          display: block;
        }
      }

     
      &.auth-wrapper--signup {
        h2 {
          font-size: 3.6rem;
          font-weight: 700;
        }

  
      }

      .auth-forgot-wrapper{
        margin-top: 6rem !important;
      }

      
      .text-small {
        margin-top: -30px !important;
        font-size: 16px;
        font-weight: 400;
        font-size: 16px;
        // line-height: 26px;
        
        letter-spacing: 0.3px;

              }

              .text-small_verification{
                margin-top: -20px;
                margin-left: 40px;
              }

@media(max-width:1600px){
    .text-small {
        margin-top: -10px !important;
              }
              .text-small_verification{
        margin-top: -10px !important;
        margin-left: 1px;

              }

          

}

@media(min-width:600px){
  .auth-wrapper{
    text-align: center;
  &__reset{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    font-family: 'Poppins';
    font-style: normal;
    .link-btn{
      margin-top: 10px !important;
      &:hover{
        cursor: pointer;
      }
    }
  }
  


 
}


}

// rest
&__reset{
display: flex;
justify-content: center;
flex-direction:column;
font-family: 'Poppins';
font-style: normal;
flex-wrap: wrap;

p{
  font-weight: 400;
  font-size: 16px;
  line-height: 18px; 


}

.link-btn{
    background: $primary;
    width: 82%;
  padding: 0.5em 1em;
border-radius: 8px;
display: block;
text-align: center;
color: #fff;
margin-top: 25px;

a{

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
    &:hover,
    &:focus {
      background: darken($primary, 10%);
      color: #fff;
      outline: 0;
    }
}

.link-btn-nobg{
    width: 82%;
  padding: 0.5em 1em;
border-radius: 8px;
display: block;
text-align: center;
border:1px solid $primary ;
color: $primary;

margin-top: 25px;

a{

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary;
}
    &:hover,
    &:focus {
      // background: darken($primary, 10%);
      // color: #000;
      outline: 0;
    }
}






.resend-link{
  margin-top: 10px;
  a{
    color:  #9797DE;
  }
}


}


      .ant-form {
        label {
          font-size: 1.6rem;
          margin-bottom: 0px;
        }
        @media (min-width: 1600px) {
          font-size: 1.8rem;
        }
        .text-small {
          margin-top: -15px;
          font-size: 1.4rem;
          span {
            color: #767676;
          }
          a {
            font-size: 1.4rem;
            margin-left: 7px;
          }
          &-account {
            margin-top: 0;
          }
        }
        .btn-large {
          width: 100%;
        }
      }

      &--success {
        display: grid;
        place-content: center;
        text-align: center;

        h2 {
          color: rgb(30, 141, 30);
          img {
            height: 44px;
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        // line-height: 26px;
        font-family: 'Poppins';
        letter-spacing: 0.1px;
font-style: normal;
color:#1C1B1F !important;
opacity: 0.7;

        // &:last-child {
        //   font-size: 1.8rem;
        //   color: rgba(black, 0.6);

        //   span {
        //     margin-right: 7px;
        //   }
        // }
      }
    }

    .social-login {
      text-align: center;
      .social-divider {
        position: relative;
        height: 4rem;
        span {
          background: #f5fafd;
          padding: 0 20px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
          font-size: 2rem;
        }

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          background: #dbdbdb;
          width: 100%;
          left: 0;
          top: 16px;
          z-index: 3;
        }
      }

      .social {
        display: flex;
        justify-content: center;
        margin: 1.5rem 0 1.5rem;
        @media (min-width: 1600px) {
          margin: 2.5rem 0 1.5rem;
        }
        &-icon {
          position: relative;
          height: 9rem;
          width: 9rem;
          background: white !important;
          box-shadow: 1px 2px 4px rgba(black, 0.1) !important;
          justify-content: center !important;
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          border-radius: 8px !important;
          & > div {
            margin: 0 !important;
            svg {
              font-size: 36px !important;
            }
          }
          span {
            font-family: "SofiaPro" !important;
            font-size: 1.5rem !important;
            color: #767676 !important;
            font-weight: 300 !important;
            padding: 0 !important;
          }

          // @media (min-width:1600px) {
          //     height: 7.5rem;
          // width: 7.5rem;
          // }

          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }

          img {
            width: 36px;
            height: auto;
            display: inline-block;
          }
          h4 {
            margin: 7px 0 0;
            font-size: 1.5rem;
            color: #767676;
            font-weight: 300;
          }
          &:not(:last-child) {
            margin-right: 25px;
          }
        }
      }
    }
  }

  
  }
  
