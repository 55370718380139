.app-download-message-modal {
  display: none;

  @media (max-width: "600px") {
    & {
      display: block;
    }
  }
}

.app-message-modal {
  &-image {
    text-align: center;
    margin-top: 20px;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 159%;
    color: #1c1b1f;
  }

  &-points {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      margin-left: 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.2px;
      color: #1c1b1f;
      opacity: 0.8;
    }
  }

  &-button {
    margin-top: 20px;
    .btn {
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      font-size: 16px;
    }
  }
}


//  braeadscumbs
.breadcrumbs{
  margin-top: -20px;
  padding-bottom:10px;
}