.footer-wrapper {
    margin-top: 50px;

    .img-wrapper {
        margin-bottom: -200px;
    }

    @media(max-width:600px) {
        .img-wrapper {
            margin-bottom: -100px;
        }
        
    }

    .links-wrapper {
        background: #9797DE;
        padding: 20px;

        .social-links-wrapper {
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: center;
            row-gap: 40px;
            padding-top: 200px;

            .social-links-item {
                margin-left: -30px;

                ul {
                    display: flex;
                    column-gap: 25px;

                    li {
                        list-style: none;
                     

                        .social-link {
                            width: 40px;
                            position: relative;
                            height: 40px;
                            background: #FFFFFF;
                            border-radius: 50%;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);

                            }
                        }:hover{
                            cursor: pointer;
                            background: #7777af;
                            transition: all 0.4s ease-in;

                        }

                    }
                }
            }
        }
        .footer-last{
            padding: 0px 40px !important;
            margin-top: 20px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
        }
    }
}