.dash-section {
  padding: 3rem 4rem;
  border-top: 2px solid rgba($primary, 0.2);
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    h2 {
      margin-bottom: 0;
    }
  }

  .horizontal-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > .card {
      width: 48%;
      margin-bottom: 35px;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &.dash-section--playlist {
    padding-top: 0;
    border-top: 0;
  }

  &.dash-section--discover-banner {
    .row {
      background: transparent;
      background: url(../../images/discover.png) center no-repeat;
      background-size: cover;
      border-radius: 16px;
      .card-banner {
        background: transparent;

        .header-primary {
          font-size: 3.8rem;
          line-height: 1.25;
          font-weight: 700;
          width: 80%;
        }
      }
    }
  }
}


.section-description{
  margin-top: -21px;
  padding: 8px 0px;
font-family: "Poppins";
font-weight: 400;
color: #656565;
font-size: 16px;
}