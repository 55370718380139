.main-layout {
  min-height: 100vh;
  overflow: hidden;
  &.active {
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
}

.site-layout {
  background: #e3e3f8;
  margin-left: 18%;

  @media (max-width: 992px) {
    margin-left: 0;
  }
}

.site-layout-with-player {
  padding-bottom: 90px;
}

.sidebar-active-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 55;
  background: rgba(black, 0.4);
  visibility: hidden;
  transition: all 200ms ease-in;
  &.active {
    visibility: visible;
  }
}
// bradcrumb-container

.bradcrumb-container {
  padding:10px 0px;

}
.bread-link{
  color: #828282;
 

}
.bread-link:hover{
  color: #9797de !important;
  }

