.maintenance {
  .container {
    height: 100vh;

    .main-popup {
    }
  }
}

.modal-content {
  border-radius: 32px !important;
}

.custom-modal {
  max-width: 1000px !important;
  width: 100%;
  padding-top: 20px !important;
  z-index: 99999999999999999999999999999999999999 !important;
  border-radius: 32px !important;

  .modal-inner {
    padding: 60px 10px 60px 70px;
  }

  .modal-inner-txt {
    margin-left: -125px;
    margin-top: -10px;
    .headingSmall {
      line-height: 60px;
      font-size: 44px !important;
      line-height: 62px;
      font-weight: 700;
      // font-family: $primary_font_family;
      letter-spacing: 0.6px;
      color: #000;
    }
  }
}

@media (max-width: 767px) {
  .custom-modal {
    margin-top: 40px;
    max-width: 1000px !important;
    width: 100%;
    padding: 20px;
    .modal-inner {
      padding: 60px 10px 60px 70px;
    }
    .modal-inner-txt {
      margin-left: 0px;
      margin-top: 20px;
      .headingSmall {
        line-height: 50px;
      }
    }
  }
}

@media (max-width: 576px) {
  .custom-modal {
    max-width: 1000px !important;
    width: 100%;
    padding-top: 20px !important;
    z-index: 99999999999999999999999999999999999999 !important;
    border-radius: 32px !important;

    .modal-inner {
      padding: 60px 10px 60px 70px;
    }

    .modal-inner-txt {
      margin-left: 0px;
      margin-top: 0px;
      .headingSmall {
        font-size: 30px !important;
        line-height: 30px !important;
        font-weight: 700;
        letter-spacing: 0.6px;
      }
    }
  }
}

// @media (max-width: 400px) {
//   .headingSmall {
//     font-size: 20px !important;
//     line-height: 42px;
//     font-weight: 700;
//     letter-spacing: 0.6px;
//   }
// }

@media (max-width: 480px) {
  .custom-modal {
    margin-top: 40px;
    max-width: 1000px !important;
    width: 100%;
    padding: 20px;
    .modal-inner {
      padding: 60px 20px 60px 20px;
    }
    .modal-inner-txt {
      margin-left: 0px;
      margin-top: 20px;
      .headingSmall {
        line-height: 50px;
      }
    }
  }
}
