.faq-wrapper{
    margin-top: 50px;
    .heading{
display: flex;
align-items: center;
justify-content: center;
padding-bottom: 40px;
    h2{
        font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 44px;
line-height: 140%;
/* identical to box height, or 62px */

letter-spacing: 0.6px;

/* onBackground */

color: #444444;
    }
}
.ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background-color: #ffff;
    border:none;
    border-bottom: 0;
    border-radius: 2px;

}
.ant-collapse-item{
    background: #F5F5FF !important;
    border-radius: 8px;
    border:none !important;
    margin-bottom: 20px !important;
.faq-wrapper-item{
    ul{
        margin-left: -20px;
        li{
        list-style: none;
        font-family: 'Poppins';
font-style: normal;
font-weight: 400;
// display: flex;
// align-items: center;
column-gap: 10px;
font-size: 16px;
margin-top: 20px;
        }
    }
}
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */

letter-spacing: 0.015em;

/* onBackground */

color: #444444;
}
}