.offer-layout{
    padding:0px 150px;
    position: relative
    ;
    .left-img{
        position: absolute;
        top: -11px;
        left: -8px;
    }


}
@media(max-width:320px){
    .offer-layout{
        .left-img{
            display: none;
          
        }
    }
}
@media(max-width:767px){
    .offer-layout{
        padding:0px 20px;
    }
}


@media(max-width:1200px){
    .offer-layout{
        padding:0px 20px;
    }
}
