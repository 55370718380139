.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px 15px;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 500;
  text-shadow: none;
  border-radius: 8px;
  line-height: 1;
  letter-spacing: 0.5;
  border: none;
  height: auto;
  @media (max-width: 1025px) {
    font-size: 1.6rem;
    padding: 10px 25px 7px;
  }
}

.auth-btns {
  padding: 0.5em 1em;
}
.btn-primary {
  color: #fff;
  background: $primary;

  &:hover,  
  &:focus {
    background: darken($primary, 10%);
    color: #fff;
    outline: 0;
  }
}

.btn-outline {
  border: 2px solid $primary;
  color: $primary;
  background: transparent;
  &:hover {
    border: 2px solid $primary;
    background: $primary;
    color: white;
  }

  svg {
    margin-right: 15px;
    font-size: 2.5rem;
  }
}

.btn-link {
  display: inline-flex;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  text-shadow: none;
  transition: all 400ms ease;
  color: $primary;
}

.btn-large {
  height: 5rem;
  font-size: 2rem;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  @media (min-width: 1300px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.btn-grp {
  .btn {
    &:not(:last-child) {
      margin-right: 2.5rem;
    }
  }
}

.btn-circle {
  padding: 15px;
  border-radius: 50%;
}
.btn-circle-outlined {
  border: 2px solid $primary;
  svg {
    color: $primary;
    font-size: 2rem;
  }
}

.btn-circle-large {
  height: 50px;
  width: 50px;
}

.btn-table {
  color: $primary;
  font-size: 2rem;

  &.btn-heart {
    color: $primary;
  }
}

.link-primary {
  color: $primary;
}

button {
  border: none;

  &:hover {
    border: 0;
    box-shadow: none;
  }
}
