.card--personal-info {
  background: rgba(white, 0.87);
  border-radius: 16px;
  flex-direction: column;
  padding: 2rem 3rem;
  &:not(:first-child) {
    margin-top: 30px;
  }

  &--avatar {
    height: 100%;

    .btn--update-profile {
      margin-top: 30px;
    }
  }
  .card__header {
    font-size: 2.6rem;
  }
  .row--avatar {
    margin-top: 3rem;
  }
  .avatar-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .settings-personal-info-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .btn {
      max-width: 230px;
      width: 100%;
      &:last-child {
        margin-top: 20px;
      }
    }
  }

  form.auth-form-wrapper {
    margin-top: 4rem;
  }

  .switch-wrapper {
    margin-top: 1rem;
    .switch-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }

      .ant-switch {
        background-color: #d5d5d5;
        min-width: 64px;
        height: 30px;

        .ant-switch-handle {
          height: 24px;
          width: 24px;
          top: 3;
          left: 3;
          &:before {
            background-color: #959595;
            border-radius: 50%;
          }
        }

        &-checked {
          background: lighten($primary, 5%);

          .ant-switch-handle {
            left: calc(100% - 18px - 10px);
            &:before {
              background-color: white;
            }
          }
        }
      }
    }
  }

  form.form--password {
    margin-top: 1rem;
  }

  .payment-card-info {
    // display: flex;
    // align-items: center;

    img {
      width: 54px;
      margin-right: 10px;
    }

    .payment-card-text {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      h5 {
        margin-bottom: 0;
        font-size: 1.6rem;
      }
      p {
        font-size: 18px;
        margin-bottom: 0;
        color: #1c1b1f;
        span {
          font-weight: bold;
        }
      }
      .cancel-btn {
        button {
          color: #9797de;
        }
      }
      .upgrade-btn {
        button {
          background-color: #9797de;
          color: #fff;
        }
      }
    }
    .subcription-date {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      justify-items: center;

      .purches-date {
        display: flex;
        p {
          font-size: 18px;
          margin-bottom: 0;
          color: #1c1b1f;
          span {
            font-weight: bold;
          }
        }
      }

      .expire-date {
        p {
          font-size: 18px;
          margin-bottom: 0;
          color: #ff2c2c;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.ant-modal.avatar-modal {
  .ant-modal-content {
    .ant-modal-header {
      border: none;
      padding: 2rem;
      .ant-modal-title {
        font-size: 2.2rem;
      }
    }

    .ant-modal-body {
      .modal-body {
        .avatar-img {
          height: 175px;
          width: 175px;
          flex: 1 0 auto;
          margin-right: 2rem;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &-photo-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 400px;
          background: #efefef;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

          &-content {
            text-align: center;

            p {
              margin-top: 6px;
              font-weight: 400;
              font-size: 16px;
              line-height: 185%;
              color: #1c1b1f;
            }
          }
        }

        &-button {
          margin-top: 20px;
          text-align: center;
          position: relative;

          .ant-upload-list-text {
            width: "100%";
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &-file {
            position: relative;
            text-align: right;
            -moz-opacity: 0;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
          }

          button {
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
          }
        }
      }
    }
  }
}

// delete account container
.delete-account-container {
  margin-top: 25px;

  &-modal {
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #1c1b1f;
    }

    &-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.2px;
      color: #1c1b1f;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 15px;

      .btn {
        margin-left: 15px;
      }
    }

    &-options {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      cursor: pointer;

      &-unselected {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #cecece;
      }
      &-selected {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #9797de;
        box-shadow: 0px 2px 4px rgba(115, 103, 240, 0.4);
      }

      &-title {
        margin-left: 15px;
        margin-bottom: 0;
      }
    }

    &-delete-title,
    &-logout {
      // display: flex;
      // justify-content: center;
      text-align: center;
    }
  }
}
