@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  src: url("../../font/SofiaProRegular.otf");
}
@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 500;
  src: url("../../font/SofiaProMedium.otf");
}
@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 700;
  src: url("../../font/SofiaProBold.otf");
}
@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 900;
  src: url("../../font/SofiaProBlack.otf");
}
@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 300;
  src: url("../../font/SofiaProLight.otf");
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "SofiaPro";
  color: #181818;
}

.auth-required {
  .auth-btns {
    border-color: white !important;
    color: white !important;
    position: relative;
    z-index: 99999;
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
  }
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.3;
}

.mb-4 {
  margin-bottom: 4em;
}

.mb-6 {
  margin-bottom: 6em;
}
