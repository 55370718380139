.header {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: auto;
  line-height: unset;
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .search-bar {
    position: relative;
    .iconly-Search {
      font-size: 24px;
      color: rgba($primary, 0.6);
    }
    .ant-input-affix-wrapper {
      border: none;
      border-radius: 8px;

      &:focus {
      }
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px rgba($primary, 0.2);
    }
    input {
      height: 40px;
      width: 300px;
      border: 0;
      background: rgba(white, 0.6);
      border-radius: 8px;
      padding: 0 15px 0 7px;

      @media (min-width: 1200px) {
        width: 500px;
      }
    }

    @media (max-width: 991px) {
      width: 350px;
    }

    @media (max-width: 768px) {
      display: none;
    }

    &__suggestion-container {
      background-color: #fff;
      position: absolute;
      z-index: 10;
      top: 45px;
      left: 0;
      width: 100%;
      border-top: 2px solid lighten($primary, 20%);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      max-height: 50vh;
      overflow-y: scroll;
      ul {
        list-style-type: none;
        padding-left: 0;
      }
      li {
        cursor: pointer;
        padding: 10px;
        font-size: 1.4rem;
        &:not(:last-child) {
          border-bottom: 1px solid #f1f1f1;
        }

        &:hover {
          color: rgba($primary, 0.9);
        }
      }
    }
  }

  .header-menu {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 25px;
    }
    .anticon {
      font-size: 2.6rem;
      color: $primary;
      position: relative;
      top: 2.5px;
      // vertical-align: middle;
    }

    // .header-menu__item {
    //   @media (max-width: 991px) {
    //     display: none;
    //   }
    // }
  }

  .sidebarmenu-icon {
    font-size: 2.8rem;
    color: $primary;
    @media (min-width: 992px) {
      display: none;
    }
  }
}

.user-menu {
  padding: 0;
  background-color: #f4fafe;
  li {
    padding: 0.6em;
    font-size: 1.6rem;
  }
}
