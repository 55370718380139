.d-none {
  display: none;
}

.text-primary {
  color: $primary;
}

.d-flex-ac {
  display: flex;
  align-items: center;
}

@mixin hideScrollbar {
  // https://blogs.msdn.microsoft.com/kurlak/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari/
  // There is a CSS rule that can hide scrollbars in Webkit-based browsers (Chrome and Safari).
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  // There is a CSS rule that can hide scrollbars in IE 10+.
  -ms-overflow-style: none;

  // Use -ms-autohiding-scrollbar if you wish to display on hover.
  // -ms-overflow-style: -ms-autohiding-scrollbar;

  // There used to be a CSS rule that could hide scrollbars in Firefox, but it has since been deprecated.
  scrollbar-width: none;
}

.img-fluid {
  width: 100%;
}

.p-disabled {
  margin: 0 !important;
  margin-bottom: 0 !important;
  span {
    font-weight: bold !important;
  }
}

.ml-05 {
  margin-right: 0.5em;
}

.text-disabled {
  color: #8e8e8e;
  position: relative;
  z-index: 9999;
  svg {
    fill: #8e8e8e;
  }
}
