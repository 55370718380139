.section-genre-list {
  background: #e3e3f8;
  padding: 6rem;
  min-height: 100vh;
  position: relative;
  .row {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    h2 {
      span {
        font-weight: 700;
      }
    }

    .genre-list {
      margin-top: 30px;
      margin-left: -20px;
      margin-right: -20px;

      &__item {
        position: relative;
        padding: 20px;

        .card-round {
          width: 20rem;
          height: 20rem;
          border-radius: 50%;
          position: relative;
          h4 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 7;
          }
          .image-wrapper {
            position: relative;
            &::before {
              position: absolute;
              content: "";
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              background: rgba(black, 0.4);
              z-index: 5;
            }
          }

          &.card-selected {
            border: none;
            box-shadow: none;
            .image-wrapper {
              &:before {
                background: rgba(151, 151, 222, 0.75);
              }
            }
          }
        }
      }
    }
  }
  .row-btn {
    text-align: right;
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
}

.genre-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  margin-left: -20px;
  margin-right: -20px;

  &__item {
    position: relative;
    padding: 20px;
  }
}



@media(max-width:468px){
  .genre-list {
    align-items: center;
    justify-content: center;
  
    &__item {
      position: relative;
      padding: 20px;
    }
  }

}

.other-tags-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1c1b1f;
}

.section-commitment {
  padding-top: 50px;
  &-list {
    display: flex;
    justify-content: center;

    .commitment-container {
      max-width: 560px;

      .commitment-btn {
        float: right;
        margin-top: 30px;
      }
    }
  }
}
