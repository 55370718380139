.ant-progress-line {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;

  .ant-progress-text {
    margin-left: 0;
    margin-right: 10px;
    margin: 3px 10px 0 0;
    color: $primary;
    font-weight: 700;
    font-size: 1.6rem;
  }
}


// .ant-modal-confirm-body .ant-modal-confirm-title {
//   font-size:20px;
//   font-weight: bold;
//   line-height: 24px;
//   }

  .ant-btn-primary {
    // color: #fff;
    color: #fff;
    background: #9797de;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    border: none;
  
}
.ant-modal{
  border-radius: 5px !important;
}
.ant-btn-primary:hover {
  background: #7070d2;;

}

.ant-btn{
  border: none;
}