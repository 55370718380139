.sidebar {
  background: #f4fafe;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  // overflow: hidden;
  width: 18% !important;
  max-width: 100% !important;
  z-index: 99;
  overflow-y: auto;
  @include hideScrollbar;

  @media (max-width: 991px) {
    width: auto;
    transform: translateX(-100%);
    transition: all 350ms ease-in-out;
  }

  &.active {
    transform: translateX(0);
  }
  .logo {
    padding: 30px 20px 0;

    @media (min-width: 1600px) {
      padding: 35px 20px 0 30px;
    }
    img {
      height: 32px;
      @media (min-width: 1200px) {
        height: 40px;
      }
    }
  }

  &__menu {
    margin-top: 3rem;
    font-size: 1.6rem;
    background: transparent;
    &:first-child {
      margin-top: 4rem;
    }

    h4 {
      font-size: 1.4rem;
      color: #666;
      padding: 0 20px !important;
      margin-bottom: 2rem;
      font-weight: 300;
      @media (min-width: 1600px) {
        padding-left: 30px !important;
      }
    }

    li {
      padding: 0 20px !important;
      color: #212121;
      font-size: 1.8rem;

      @media (min-width: 1600px) {
        padding-left: 30px !important;
      }
      .anticon {
        font-size: 1.8rem;
      }

      .icon-menu {
        font-size: 22px;
        margin-right: 10px;
        color: $primary;
      }
      &.ant-menu-item-selected {
        background: none !important;
        color: $primary;
        a {
          color: $primary;
        }
        &:after {
          border-right: 4px solid $primary !important;
        }
      }

      &:hover,
      &:hover a,
      &:focus a,
      &:focus {
        color: $primary !important;
      }

      &.text-primary {
        .anticon,
        a {
          color: $primary;
        }
      }
    }

    &.sidebar__menu--playlist {
      border-top: 2px solid lighten($primary, 15%);
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  display: none;
}

.ant-menu-item {
  .active {
    color: $primary;
  }
}

.ant-modal-body {
  background: #ffffff;
  // box-shadow: 0px 38px 80px rgba(151, 151, 222, 0.0281146), 0px 15.8755px 33.4221px rgba(151, 151, 222, 0.0403893), 0px 8.4878px 17.869px rgba(151, 151, 222, 0.05), 0px 4.75819px 10.0172px rgba(151, 151, 222, 0.0596107), 0px 2.52704px 5.32008px rgba(151, 151, 222, 0.0718854), 0px 1.05156px 2.21381px rgba(151, 151, 222, 0.1);
  border-radius: 8px;

  .ant-btn-primary {
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    gap: 10px;

    height: 38x;

    background: #9797de;
    border: 1px solid #9797de;
    border-radius: 8px;
  }
  .ant-btn {
    border: none;
  }
}

.sidebar-playlist {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-btn {
    display: none;
    &-icons {
      display: flex;
      margin-right: 10px;

      &-delete {
        margin-left: 5px;
        cursor: pointer;
      }
      &-edit {
        cursor: pointer;
      }
    }
  }
}

.sidebar-playlist:hover > .sidebar-playlist-btn {
  display: block;
  transition: 0.3s all ease-in;
  color: $primary !important;
}

.edit-form-label {
  font-size: 18px;
  font-weight: 500;
  height: 22px !important;
  margin-bottom: 10px;
}

.edit-form-input {
  padding: 6.5px 11px;
  font-size: 16px;
}

.edit-form-error {
  color: #ff4d4f;
  font-size: 14px;
}

.categories {
  .sidebar__menu {
    margin-top: 16px;

    img {
      margin-right: 8px;
    }
  }
}
